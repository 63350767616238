import { makeAutoObservable } from 'mobx';

class trackingStore {
  tails = {};
  activePilotData = {};
  activePilot = '';
  activeAirZone = false;
  activeAirZoneAC = '';
  activeAirZoneAL = '';
  activeAirZoneAH = '';
  activeAirZoneAN = '';
  constructor() {
    makeAutoObservable(this);
  }
  setActivePilotID(id) {
    this.activePilot = id;
    if (id == '') this.activePilotData = {};
    //console.log('aprsid=' + this.activePilot);
  }
  setActivePilotData(data) {
    this.activePilotData = data;
    //console.log(this.activePilotData.noSignalTime);
  }
  deleteTail(key) {
    delete this.tails[key];
  }
  deleteAllTail() {
    for (var key in this.tails) {
      delete this.tails[key];
    }
  }
  setActiveAirSpace(AC, AL, AH, AN) {
    this.activeAirZone = true;
    this.activeAirZoneAC = AC;
    this.activeAirZoneAL = AL;
    this.activeAirZoneAH = AH;
    this.activeAirZoneAN = AN;
  }
  unsetActiveAirSpace() {
    this.activeAirZone = false;
  }
}

export default new trackingStore();
