import React from 'react';
import { Polyline, Marker, Circle, Popup } from 'react-leaflet';
import 'leaflet-semicircle';
import L from 'leaflet';

import { TrLine } from '../helpers/TrLine';
import SemiCircle from '../Controls/SemiCircle';

function TaskLinesView({ turnPoints }) {
  const ppmLabels = [];
  const ppmAreas = [];

  turnPoints.forEach((point, idx) => {
    let tpName = 'TP' + idx + ' ' + point.TPName;
    if (idx === turnPoints.length - 1) tpName = 'Finish';
    if (idx === 0) tpName = 'Start';

    const icon = L.divIcon({
      className: 'turn-point-name', // Set class for CSS styling
      html: `
        <div style="display: flex;justify-content: center;">
          <b style="
            background-color: #ffffff;
            border: 1px solid;
            border-radius: 3px;
            font-weight: bolder;
            font-size: 12px;
            padding-left: 2px;
            padding-right: 2px;
            text-align: center;
            top: -20px;
            position: absolute;
            white-space: nowrap;
          ">${tpName}</b>
        </div>
        `
    });
    ppmLabels.push(
      <Marker key={idx} position={[point.lat, point.lon]} icon={icon}>
        <Popup>
          <table>
            <tbody>
              <TrLine a="Имя" b={point.TPName} />
              <TrLine a="Превышение" b={point.alt + ' м'} />
              <TrLine a="Радиус" b={point.TPRadius > 1000 ? point.TPRadius / 1000 + ' км' : point.TPRadius + ' м'} />
              {!!+point.legDistance && <TrLine a="Предыдущий отрезок" b={point.legDistance + ' км'} />}
              {!!+point.nextTpDist && <TrLine a="Следующий отрезок" b={point.nextTpDist + ' км'} />}
            </tbody>
          </table>
        </Popup>
      </Marker>
    );

    if (point.TPAngle === 360) {
      ppmAreas.push(
        <Circle
          key={idx}
          center={[point.lat, point.lon]}
          radius={point.TPRadius}
          pathOptions={{ color: 'red', weight: 2, fillColor: 'green', fillOpacity: 0.3 }}
          interactive={false}
        />
      );
    } else {
      ppmAreas.push(
        <SemiCircle
          key={idx}
          center={[point.lat, point.lon]}
          color="red"
          fillColor="green"
          fillOpacity="0.3"
          direction={point.TPOuterAngle + 180}
          angle={point.TPAngle}
          radius={point.TPRadius}
          interactive={false}
        />
      );
    }
    return;
  });

  const taskLines = turnPoints.map((point) => {
    return [point.lat, point.lon];
  });

  return (
    <>
      {ppmLabels}
      {ppmAreas}
      <Polyline pathOptions={{ color: 'blue', weight: 2 }} positions={taskLines} interactive={false} />
    </>
  );
}

export default TaskLinesView;
