import React from 'react';
import { LayerGroup } from 'react-leaflet';
import { observer } from 'mobx-react-lite';

import settingsStore from '../Store/settingsStore';
import AirSpacesView from './AirSpaceView';
import AirSpaceLabel from './AirSpaceLabel';
import WeatherZoneView from './WeatherZoneView';
import TaskLinesView from './TaskLinesView';
import { windSpeed } from '../helpers/helpers';

function Task({ currentTask, trackingType }) {
  if (!currentTask) return;
  const windDir = Math.round(currentTask.windDir);
  const windSpeedTitle = windSpeed(currentTask.windSpeed);

  return (
    <LayerGroup>
      <TaskLinesView turnPoints={currentTask.turnPoints} />
      {trackingType == 'condor' && settingsStore.showWeatherZones && (
        <WeatherZoneView zones={currentTask.weatherZones} />
      )}
      {trackingType == 'condor' && settingsStore.showPenaltyZones && (
        <AirSpacesView zones={currentTask.penaltyZones} type="penaltyZones" />
      )}

      <AirSpacesView zones={currentTask.airSpaces} type="AirSpaces" />

      {currentTask.raceType != 'FF' && (
        <div
          style={{
            bottom: `80px`,
            right: `15px`,
            position: `fixed`,
            zIndex: `500`,
            opacity: `0.8`,
            transform: `rotate(${windDir}deg)`
          }}>
          <img title={windSpeedTitle} alt={windDir} src="/strelka2.png" width="40" height="60" />
        </div>
      )}
      <AirSpaceLabel />
    </LayerGroup>
  );
}

export default observer(Task);
