import React from 'react';

export function TrLine({ a, b }) {
  return (
    <tr>
      <td>
        <b>{a}:</b>
      </td>
      <td>{b}</td>
    </tr>
  );
}
