import React, { useState } from 'react';
import { Polyline } from 'react-leaflet';
import { ToggleButton, Badge } from 'react-bootstrap';
import { MdOutlineSsidChart } from 'react-icons/md';
import styles from '../styles/Home.module.css';

function AatPathsButton({ currentTask }) {
  const [showAatPaths, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);

  if (!currentTask || currentTask.raceType == 'FF') return <></>;

  const aatMaxLines = currentTask.aatTask.max.points.map((point) => {
    return [point.lat, point.lon];
  });

  const aatMinLines = currentTask.aatTask.min.points.map((point) => {
    return [point.lat, point.lon];
  });
  const linesOpacity = showAatPaths == true ? 1 : 0;

  return (
    <>
      <div
        style={{
          bottom: `80px`,
          left: `10px`,
          position: `fixed`,
          zIndex: `1999`
        }}>
        <ToggleButton
          variant="secondary"
          type="checkbox"
          checked={linesOpacity}
          onClick={toggleShow}
          style={{ paddingLeft: '10px', width: '45px', height: '45px' }}>
          <MdOutlineSsidChart style={{ fontSize: 'x-large' }} />
        </ToggleButton>
        <Badge
          pill
          bg="danger"
          style={{
            top: `4px`,
            left: `35px`,
            position: `absolute`,
            opacity: linesOpacity
          }}>
          <strong className={styles.buttonBadgeText}>{currentTask.aatTask.max.dist} км</strong>
        </Badge>
        <Badge
          pill
          bg="warning"
          text="dark"
          style={{
            top: `24px`,
            left: `35px`,
            position: `absolute`,
            opacity: linesOpacity
          }}>
          <strong className={styles.buttonBadgeText}>{currentTask.aatTask.min.dist} км</strong>
        </Badge>
      </div>
      <Polyline
        pathOptions={{
          color: 'red',
          weight: 2,
          dashArray: '10, 10',
          dashOffset: '0',
          opacity: linesOpacity
        }}
        positions={aatMaxLines}
        interactive={false}
      />
      <Polyline
        pathOptions={{
          color: 'orange',
          weight: 2,
          dashArray: '10, 10',
          dashOffset: '0',
          opacity: linesOpacity
        }}
        positions={aatMinLines}
        interactive={false}
      />
    </>
  );
}

export default AatPathsButton;
