import { observer } from 'mobx-react-lite';
import trackingStore from '../Store/trackingStore';

function AirSpaceLabel() {
  if (trackingStore.activeAirZone == false) return;
  return (
    <div
      style={{
        bottom: `30px`,
        left: `180px`,
        position: `fixed`,
        zIndex: `9999`,
        backgroundColor: `white`,
        paddingLeft: `5px`,
        paddingRight: `5px`
      }}>
      <table>
        <tbody>
          <tr>
            {trackingStore.activeAirZoneAC != '' && (
              <td style={{ paddingLeft: `5px`, paddingRight: `5px` }}>
                <b>AC: </b>
                {trackingStore.activeAirZoneAC}
              </td>
            )}
            {trackingStore.activeAirZoneAL != '' && (
              <td style={{ paddingLeft: `5px`, paddingRight: `5px` }}>
                <b>AL: </b>
                {trackingStore.activeAirZoneAL}
              </td>
            )}
            <td> - </td>
            {trackingStore.activeAirZoneAH != '' && (
              <td style={{ paddingLeft: `5px`, paddingRight: `5px` }}>
                <b>AH: </b>
                {trackingStore.activeAirZoneAH}
              </td>
            )}
            {trackingStore.activeAirZoneAN != '' && (
              <td style={{ paddingLeft: `5px`, paddingRight: `5px` }}>
                <b>AN: </b>
                {trackingStore.activeAirZoneAN}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default observer(AirSpaceLabel);
