import { TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import WindyLayer from './Controls/WindyLayer';
var jsonData = [];

// var rainviewerJson = [];
// var radarPath = '';
// var satPath = '';
// var radarHost = '';
var radarLink = '';
var satLink = '';

// fetch('https://tracking.crc.team/api/live/getWind.php')
//   .then((res) => {
//     if (res.status === 200) return res.json();
//     else return [];
//   })
//   .then(
//     (list) => {
//       jsonData = list;
//     },
//     (error) => {
//       if (error) {
//         console.log('wind loading error');
//       }
//     }
//   );

// fetch('https://api.rainviewer.com/public/weather-maps.json')
//   .then((res) => {
//     if (res.status === 200) return res.json();
//     else return [];
//   })
//   .then(
//     (list) => {
//       rainviewerJson = list;
//       radarPath = rainviewerJson['radar']['past'][12]['path'];
//       satPath = rainviewerJson['satellite']['infrared'][11]['path'];
//       radarHost = rainviewerJson['host'];
//       radarLink = radarHost + radarPath + '/256/{z}/{x}/{y}/2/1_1.png';
//       satLink = radarHost + satPath + '/256/{z}/{x}/{y}/0/0_0.png';
//       //console.log(radarLink);
//     },
//     (error) => {
//       if (error) {
//         console.log('rainviewerJson loading error');
//       }
//     }
//   );

function MapLayersControl(props) {
  const { trackingType } = props;

  //https://tileserver.4umaps.com/{z}/{x}/{y}.png
  return (
    <LayersControl position="bottomright">
      <LayersControl.BaseLayer checked={trackingType == 'live' && true} name="OSM">
        <TileLayer attribution="OSM" url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" maxNativeZoom={16} />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenTopoMap">
        <TileLayer attribution="OpenTopoMap" url="https://a.tile.opentopomap.org/{z}/{x}/{y}.png" maxNativeZoom={14} />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="thunderforest - cycle">
        <TileLayer
          attribution="thunderforest"
          subdomains={['a', 'b', 'c']}
          url="https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=c6892720b38b40b2bf1d44e13367404c"
          maxNativeZoom={17}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="thunderforest - landscape">
        <TileLayer
          attribution="thunderforest_landscape"
          subdomains={['a', 'b', 'c']}
          url="https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=c6892720b38b40b2bf1d44e13367404c"
          maxNativeZoom={17}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked={trackingType == 'condor' && true} name="Рельеф">
        <TileLayer
          attribution="Рельеф"
          url="https://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg"
          maxNativeZoom={11}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Outdoor by Mapy.cz">
        <TileLayer
          attribution="Outdoor by Mapy.cz"
          url="https://tiles.windy.com/v1/maptiles/outdoor/256%402x/{z}/{x}/{y}/?lang=en"
          maxNativeZoom={15}
        />
      </LayersControl.BaseLayer>
      {trackingType == 'live' && (
        <LayersControl.Overlay name="Осадки">
          <TileLayer
            attribution="Осадки"
            url="https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=57825199ce362910da7fececad3af920"
            maxNativeZoom={11}
          />
        </LayersControl.Overlay>
      )}
      {trackingType == 'live' && (
        <LayersControl.Overlay name="Облачность">
          <TileLayer
            attribution="Облачность"
            url="https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=57825199ce362910da7fececad3af920"
            maxNativeZoom={11}
          />
        </LayersControl.Overlay>
      )}
      {trackingType == 'live' && (
        <LayersControl.Overlay name="Радар">
          <TileLayer attribution="Радар" url={radarLink} maxNativeZoom={11} />
        </LayersControl.Overlay>
      )}
      {trackingType == 'live' && (
        <LayersControl.Overlay name="Спутник">
          <TileLayer attribution="Спутник" url={satLink} maxNativeZoom={11} />
        </LayersControl.Overlay>
      )}
      {trackingType == 'live' && (
        <LayersControl.Overlay name="Ветер" checked={false}>
          <FeatureGroup pathOptions={{ color: 'purple' }}>
            {/* <TileLayer
              attribution=""
              url="https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=57825199ce362910da7fececad3af920"
              maxNativeZoom={11}
            /> */}
            <WindyLayer
              displayValues={true}
              displayOptions={{
                velocityType: '',
                displayPosition: 'bottomleft',
                displayEmptyString: '',
                showCardinal: false
              }}
              data={jsonData}
              maxVelocity={10}
              minVelocity={0}
              opacity={1}
            />
          </FeatureGroup>
        </LayersControl.Overlay>
      )}
    </LayersControl>
  );
}

export default MapLayersControl;
