import isEmpty from 'lodash/isEmpty';
import { CgRadioChecked, CgRadioCheck } from 'react-icons/cg';
import { BiArrowFromTop, BiArrowFromBottom } from 'react-icons/bi';

import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';
import 'leaflet/dist/leaflet.css';
import styles from '../styles/ServerList.module.css';

let currentServer = 0;
let airSpaceReloadCounter = 0;

var listReloadTimer;
var taskReloadTimer;
var currentAirSpace;

const LIST_POLLING_INTERVAL = 60000;
const TASK_POLLING_INTERVAL = 60000;

const mediaQuery = window.matchMedia('(max-width: 767.98px)');
if (localStorage.getItem('serverListIsOpen') === null) {
  if (mediaQuery.matches) localStorage.setItem('serverListIsOpen', false);
  else localStorage.setItem('serverListIsOpen', true);
}

const serverListIsOpen = localStorage.getItem('serverListIsOpen') === 'true' ? true : false;

function ServerList({ onChange, data, trackingType }) {
  const map = useMap();

  const [searchParams, setSearchParams] = useSearchParams();
  const serverID = searchParams.get('serverID');
  const [isOpen, setOpen] = useState(serverListIsOpen);
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [lastListDate, setLastListDate] = useState(0);
  const [lastTaskDate, setLastTaskDate] = useState(0);
  const loadLink =
    trackingType === 'live'
      ? 'https://tracking.crc.team/api/live/getTasks.php'
      : 'https://tracking.crc.team/api/condor/getTasks.php';

  useEffect(() => {
    //console.log('loading servers tasks');
    fetch(loadLink + (serverID ? `?a=${serverID}` : ''))
      .then((res) => res.json())
      .then(
        (list) => {
          if (list.length) {
            list.forEach((element) => {
              if (!list.find((s) => s.serverID === 's' + element.taskID)) {
                list.push({
                  serverID: 's' + element.taskID,
                  taskID: element.taskID,
                  serverName: element.serverName,
                  distance: element.distance,
                  taskName: element.taskName,
                  activePilots: 0
                });
              }
            });
            // Проверяем указан ли serverID при запросе
            var activeServer = null;
            if (serverID) {
              // Если указан, проверяем есль ли он в ответе от сервера
              const server = list.find((s) => s.serverID === serverID);
              activeServer = server ? server : list[0]; // Если в ответе от сервера его нет, то берем первый элемент из ответа
            } else {
              const currServer = list.find((s) => s.serverID === currentServer);
              activeServer = !currServer || currServer.activePilots == 0 ? list[0] : currServer;
            }
            if (selectedServer == null || selectedServer.serverID != activeServer.serverID) {
              setSelectedServer(activeServer);
            }
          }
          setServers(list);
          clearTimeout(listReloadTimer);
          listReloadTimer = setTimeout(() => {
            setLastListDate(new Date().toISOString());
          }, LIST_POLLING_INTERVAL);
        },
        (error) => {
          if (error) {
            console.log('Task loading error');
            console.log(error);
          }
          clearTimeout(listReloadTimer);
          listReloadTimer = setTimeout(() => {
            setLastListDate(new Date().toISOString());
          }, LIST_POLLING_INTERVAL);
        }
      );
  }, [serverID, data, lastListDate]);

  useEffect(() => {
    if (!selectedServer || selectedServer.serverID === undefined) return;
    var noairspace = '&noairspace';
    if (airSpaceReloadCounter == 0 || currentServer != selectedServer.serverID) {
      noairspace = '';
      airSpaceReloadCounter = 0;
    }
    fetch(loadLink + `?id=${selectedServer.serverID}` + noairspace)
      .then((res) => res.json())
      .then(
        (currentTask) => {
          if (isEmpty(currentTask)) {
            throw new Error(`Task on server ${selectedServer.serverID} not found`);
          }
          if (noairspace == '') {
            currentAirSpace = currentTask.airSpaces;
          } else {
            currentTask.airSpaces = currentAirSpace;
          }
          airSpaceReloadCounter++;
          if (airSpaceReloadCounter == 10) airSpaceReloadCounter = 0;
          //console.log(currentTask);
          onChange(currentTask);
          if (currentServer !== selectedServer.serverID) {
            /*if (selectedServer.raceType != 'FF')*/ map.panTo([currentTask.lat, currentTask.lon]);
            //map.setView([task.lat, task.lon], 9);
            //map.setZoom(10);
            currentServer = selectedServer.serverID;
          }
          clearTimeout(taskReloadTimer);
          taskReloadTimer = setTimeout(() => {
            setLastTaskDate(new Date().toISOString());
          }, TASK_POLLING_INTERVAL);
        },
        (error) => {
          if (error) {
            console.log('Task loading error');
            console.log(error);
          }
        }
      );
  }, [selectedServer, onChange, map, lastTaskDate]);

  let activePilots = 0;
  let serversList = [];
  // ----------------------------------
  let tasksList = [];
  servers.forEach((element) => {
    if (element.serverID.indexOf('s') == -1) {
      activePilots += element.activePilots;
      let obj = tasksList.find((o) => o.serverID === 's' + element.taskID);
      if (obj && element.activePilots > 0 && obj.activePilots > 0) {
        //if (obj) {
        obj.servers.push({
          serverID: element.serverID,
          serverName: element.serverName,
          activePilots: element.activePilots
        });
        obj.activePilots += element.activePilots;
      } else {
        tasksList.push({
          taskID: element.taskID,
          taskName: element.taskName,
          distance: element.distance,
          activePilots: element.activePilots,
          serverID: 's' + element.taskID,
          servers: [
            {
              serverID: element.serverID,
              serverName: element.serverName,
              activePilots: element.activePilots
            }
          ]
        });
      }
    }
  });
  //console.log(tasksList);
  tasksList.forEach((element) => {
    if (element.servers.length > 1) {
      serversList.push(
        <div
          className={styles.serverRow}
          key={element.serverID}
          onClick={() => {
            setSearchParams({ serverID: element.serverID });
          }}>
          {element.serverID === currentServer && (
            <div className={styles.serverActiveServer}>
              <CgRadioChecked />{' '}
            </div>
          )}
          {element.serverID !== currentServer && (
            <div className={styles.serverInActiveServer}>
              <CgRadioCheck />{' '}
            </div>
          )}
          <div className={styles.serverName}>{element.taskName}</div>
          {element.distance > 0 && <div className={styles.serverTaskDistance}>({element.distance} км)</div>}
          <div className={styles.serverPilotsCount}>({element.activePilots})</div>
        </div>
      );
      element.servers.forEach((element2) => {
        serversList.push(
          <div
            className={styles.serverRow}
            key={element2.serverID}
            onClick={() => {
              setSearchParams({ serverID: element2.serverID });
            }}>
            &nbsp; &nbsp;
            {element2.serverID === currentServer && (
              <div className={styles.serverActiveServer}>
                <CgRadioChecked />{' '}
              </div>
            )}
            {element2.serverID !== currentServer && (
              <div className={styles.serverInActiveServer}>
                <CgRadioCheck />{' '}
              </div>
            )}
            <div className={styles.serverName}>{element2.serverName}</div>
            <div className={styles.serverPilotsCount}>({element2.activePilots})</div>
          </div>
        );
      });
    } else {
      serversList.push(
        <div
          className={styles.serverRow}
          key={element.servers[0].serverID}
          onClick={() => {
            setSearchParams({ serverID: element.servers[0].serverID });
          }}>
          {element.servers[0].serverID === currentServer && (
            <div className={styles.serverActiveServer}>
              <CgRadioChecked />{' '}
            </div>
          )}
          {element.servers[0].serverID !== currentServer && (
            <div className={styles.serverInActiveServer}>
              <CgRadioCheck />{' '}
            </div>
          )}
          <div className={styles.serverName}>{element.servers[0].serverName}</div>
          <div className={styles.serverName}>-</div>
          <div className={styles.serverTaskName}>{element.taskName}</div>
          {element.distance > 0 && <div className={styles.serverTaskDistance}>({element.distance} км)</div>}
          <div className={styles.serverPilotsCount}>({element.activePilots})</div>
        </div>
      );
    }
  });
  return (
    <div className={styles.serverList}>
      <div className={styles.serverListContent} style={{ display: isOpen ? 'block' : 'none', padding: '5px' }}>
        {activePilots == 0 && <small>Прошедшие задания:</small>}
        {activePilots > 0 && <small>Активные задания:</small>}
        <br />
        {serversList}
      </div>
      <div
        className={styles.collapseBtn}
        onClick={() => {
          localStorage.setItem('serverListIsOpen', !isOpen);
          setOpen(!isOpen);
        }}>
        {isOpen ? <BiArrowFromBottom /> : <BiArrowFromTop />}
      </div>
    </div>
  );
}

export default ServerList;
