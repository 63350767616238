import React from 'react';
import { Marker, Polygon, Popup } from 'react-leaflet';
import L from 'leaflet';

import { TrLine } from '../helpers/TrLine';
import { windSpeed } from '../helpers/helpers';
import styles from '../styles/Home.module.css';

function WeatherZoneView({ zones }) {
  if (!zones) return <></>;
  const weatherZones = [];
  zones.forEach((zone, idx) => {
    if (idx > 0) {
      const zoneLabel = new L.divIcon({
        html: `
        <div style="display: flex; justify-content: center;">
          <b style="
            position: absolute;
            border: 0px solid;
            border-radius: 3px;
            font-weight: bolder;
            font-size: 12px;
            padding-left: 0px;
            padding-right: 0px;
            top: 0px;
            text-align: center;
            color: blue;
            padding-left: 2px;
            padding-right: 2px;
          ">${zone.Name}</b>
        </div>
      `,
        iconSize: [10, 10],
        className: 'glider-icon-cn'
      });
      const positions = zone.points.map((point) => {
        return [point.lat, point.lon];
      });
      const borderPositions = zone.border.map((point) => {
        return [point.lat, point.lon];
      });
      weatherZones.push(
        <Polygon key={'wz' + idx} positions={positions} interactive={false} className={styles.weatherPoligon} />
      );
      if (borderPositions.length > 0) {
        weatherZones.push(
          <Polygon
            key={'wzb' + idx}
            positions={borderPositions}
            interactive={false}
            className={styles.weatherPoligon}
          />
        );
      }
      weatherZones.push(
        <Marker key={'wzm' + idx} position={[zone.lat, zone.lon]} icon={zoneLabel}>
          <Popup>
            <table>
              <tbody>
                <TrLine a="Имя" b={zone.Name} />
                <TrLine a="Ветер" b={zone.WindDir.toFixed(0) + '° (' + windSpeed(zone.WindSpeed) + ')'} />
                <TrLine a="Температура" b={zone.ThermalsTemp.toFixed(1) + ' °C'} />
                <TrLine a="Вер.переразвития" b={(zone.ThermalsOverdevelopment * 100).toFixed(1) + '%'} />
                <TrLine a="Смещение зоны" b={zone.MoveDir.toFixed(0) + '° (' + windSpeed(zone.MoveSpeed) + ')'} />
                <TrLine a="Граница" b={zone.BorderWidth + ' м'} />
              </tbody>
            </table>
          </Popup>
        </Marker>
      );
    }
  });
  return <>{weatherZones}</>;
}

export default WeatherZoneView;
