import React, { useState, useCallback } from 'react';
import { MapContainer, ScaleControl } from 'react-leaflet';
import Ruler from 'react-leaflet-ruler';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import ServerList from './Controls/ServerList';
import Task from './Task/Task';
import Tracking from './Tracking';
import MapLayersControl from './MapLayersControl';
import AatPathsButton from './Controls/AatPathsButton';
import AprsReceivers from './Controls/AprsReceivers';
import WeatherStations from './Controls/WeatherStations';
import MapMonitor from './Controls/MapMonitor';

import ChatDialog from './Controls/ChatDialog';
import SettingsDialog from './Controls/SettingsDialog';
import InfoBar from './Task/InfoBar';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'leaflet/dist/leaflet.css';
import styles from './styles/Home.module.css';

let DEFAULT_CENTER = [54.4042, 83.2372];

export default function Home(props) {
  const [task, setTask] = useState(null);
  const [data, setData] = useState(null);
  const updateServerList = useCallback((data) => {
    setData(data);
  }, []);
  const { trackingType } = props;
  return (
    <ThemeProvider>
      <MapContainer className={styles.map} zoomControl={false} center={DEFAULT_CENTER} zoom={9}>
        <MapLayersControl trackingType={trackingType} />
        <ScaleControl imperial={false} />
        <ServerList onChange={setTask} data={data} trackingType={trackingType} />
        <Task currentTask={task} trackingType={trackingType} />
        <Tracking trackingType={trackingType} onPilotsCountChange={updateServerList} currentTask={task} />
        {trackingType === 'condor' && <ChatDialog currentTask={task} />}
        <AatPathsButton currentTask={task} />
        <SettingsDialog currentTask={task} trackingType={trackingType} />
        {trackingType !== 'condor' && <AprsReceivers currentTask={task} />}
        {trackingType !== 'condor' && <WeatherStations />}
        <MapMonitor />
        <Ruler />
      </MapContainer>
      <InfoBar task={task} trackingType={trackingType} />
    </ThemeProvider>
  );
}
