import React, { useState, useEffect } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import { Badge, ToggleButton, Modal } from 'react-bootstrap';
import { BsChatLeftTextFill } from 'react-icons/bs';
import styles from '../styles/Home.module.css';

const CHAT_POLLING_INTERVAL = 20000;

let chatMessages = [];
var reloadTimer;

function ChatDialog({ currentTask }) {
  const [lastChatDate, setLastChatDate] = useState(new Date().toDateString());
  const [show, setShow] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const toggleShow = () => {
    setShow((s) => !s);
    setUnreadMessages(0);
  };
  const keyMap = {
    SHOW_CHAT: ['shift+c', 'shift+с']
  };
  const handlers = {
    SHOW_CHAT: toggleShow
  };

  useEffect(() => {
    if (currentTask == null) return;
    fetch(`https://tracking.crc.team/api/condor/getChat.php?serverID=` + currentTask.id)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else return [];
      })
      .then(
        (list) => {
          if (list.length) {
            if (chatMessages.length > 0) {
              const lastChatKey = chatMessages[chatMessages.length - 1].key;
              if (lastChatKey < list[list.length - 1].key) {
                list.forEach(function (item) {
                  if (item.key > lastChatKey) chatMessages.push(item);
                  if (!show) setUnreadMessages(unreadMessages + 1);
                  else setUnreadMessages(0);
                  console.log('unreadMessages', unreadMessages);
                });
              }
            } else {
              chatMessages = list;
              if (!show) setUnreadMessages(list.length);
              else setUnreadMessages(0);
            }
          }
          clearTimeout(reloadTimer);
          reloadTimer = setTimeout(() => {
            setLastChatDate(new Date().toISOString());
          }, CHAT_POLLING_INTERVAL);
        },
        (error) => {
          if (error) {
            clearTimeout(reloadTimer);
            reloadTimer = setTimeout(() => {
              setLastChatDate(new Date().toISOString());
            }, CHAT_POLLING_INTERVAL);
            console.log(error);
          }
        }
      );
  }, [lastChatDate]);

  if (currentTask == null) return <></>;

  let chatItems = chatMessages.map((message) => (
    <p className={styles.chatLine} key={message.key}>
      [{message.time}] <b>{message.userName}:</b> {message.chatMessage}
    </p>
  ));
  if (chatItems.length == 0) {
    chatItems = 'Чат пуст';
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <div
        style={{
          bottom: `133px`,
          left: `10px`,
          position: `fixed`,
          zIndex: `9999`
        }}>
        <ToggleButton
          variant="secondary"
          type="checkbox"
          checked={show}
          onClick={toggleShow}
          style={{ paddingLeft: '10px', width: '45px', height: '45px' }}>
          <BsChatLeftTextFill style={{ fontSize: 'x-large' }} />
        </ToggleButton>
        <Badge
          pill
          bg="danger"
          style={{
            top: `4px`,
            left: `35px`,
            position: `absolute`,
            opacity: unreadMessages
          }}>
          <strong className={styles.buttonBadgeText}>{unreadMessages}</strong>
        </Badge>
      </div>
      <Modal show={show} onHide={() => setShow(false)} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Чат</Modal.Title>
        </Modal.Header>
        <Modal.Body>{chatItems}</Modal.Body>
      </Modal>
    </GlobalHotKeys>
  );
}

export default ChatDialog;
